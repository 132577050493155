import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';

const PICKER_DATE_FORMAT = 'YYYY-MM-DD';
const PICKER_TIME_FORMAT = 'HH:mm:ss';
const PICKER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const DATE_INTERVALS = {
  today: 1,
  yesterday: 2,
  tomorrow: 3,
  thisWeek: 4,
  thisMonth: 5,
  thisYear: 6,
  lastMonth: 7,
  next30Days: 8,
  custom: 9
};

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

function formatDateTime(date, style = 'dateTime', language) {
  if (!date) return null;
  const langCode = language ? language.split('_')[0] : 'en';
  const options = { timeZone: timezone };
  if (style === 'date') options.dateStyle = 'short';
  else if (style === 'time') options.timeStyle = 'short';
  else {
    const dateFormatter = new Intl.DateTimeFormat(langCode, {
      dateStyle: 'short',
      timeZone: timezone
    });
    const timeFormatter = new Intl.DateTimeFormat(langCode, {
      timeStyle: 'short',
      timeZone: timezone
    });
    return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;
  }

  return new Intl.DateTimeFormat(langCode, options).format(date);
}

export function dateFormatForPicker(language) {
  return language && language.startsWith('tr') ? 'DD.MM.YYYY' : 'MM/DD/YY';
}

export function dateTimeIsoToLocalizedWithTimeZone(isoText, language) {
  const date = isoText ? dayjs.utc(isoText).tz(timezone).toDate() : null;
  return formatDateTime(date, 'dateTime', language);
}

export function dateTimeIsoToLocalized(isoText, language) {
  const date = isoText ? dayjs(isoText).toDate() : null;
  return formatDateTime(date, 'dateTime', language);
}

export function dateIsoToLocalized(isoText, language) {
  const date = isoText ? dayjs(isoText).toDate() : null;
  return formatDateTime(date, 'date', language);
}

export function timeIsoToLocalized(isoText, language) {
  const date = isoText ? dayjs(isoText).toDate() : null;
  return formatDateTime(date, 'time', language);
}

export function convertDateObject(isoText) {
  return dayjs(isoText);
}

export function convertDateIsoFormat(date) {
  return dayjs(date).format(PICKER_DATE_FORMAT);
}

export function convertDateTimeIsoFormat(date) {
  return dayjs(date).format(PICKER_DATE_TIME_FORMAT);
}

export function convertTimeIsoFormat(date) {
  return dayjs(date).format(PICKER_TIME_FORMAT);
}

export function startOfDate(isoText, type) {
  const date = convertDateObject(isoText);
  return date.startOf(type);
}

export function endOfDate(isoText, type) {
  const date = convertDateObject(isoText);
  return date.endOf(type);
}

export function prepareDateFormatParams(params) {
  const dateInterval = params.dateInterval;
  let dataParams = {};

  if (dateInterval) {
    if (DATE_INTERVALS.custom === dateInterval) {
      dataParams = calculateCustomDates(params.startDate, params.endDate);
    } else {
      dataParams = calculateDates(dateInterval);
    }
  }

  return dataParams;
}

export function calculateCustomDates(startDateText, endDateText) {
  const startDate = startOfDate(startDateText, 'day');
  const endDate = endOfDate(endDateText, 'day');

  return { startDate: convertDateTimeIsoFormat(startDate), endDate: convertDateTimeIsoFormat(endDate) };
}

export function calculateDates(dateInterval) {
  const now = dayjs();
  let startDate = now.startOf('day');
  let endDate = now.endOf('day');

  switch (dateInterval) {
    case DATE_INTERVALS.today:
      break;
    case DATE_INTERVALS.yesterday:
      startDate = startDate.subtract('1', 'day');
      endDate = endDate.subtract('1', 'day');
      break;
    case DATE_INTERVALS.tomorrow:
      startDate = startDate.add('1', 'day');
      endDate = endDate.add('1', 'day');
      break;
    case DATE_INTERVALS.thisWeek:
      startDate = startDate.startOf('week');
      break;
    case DATE_INTERVALS.thisMonth:
      startDate = startDate.startOf('month');
      break;
    case DATE_INTERVALS.thisYear:
      startDate = startDate.startOf('year');
      break;
    case DATE_INTERVALS.lastMonth:
      startDate = startDate.subtract('1', 'month');
      break;
    case DATE_INTERVALS.next30Days:
      break;
    default:
      break;
  }

  return { startDate: convertDateTimeIsoFormat(startDate), endDate: convertDateTimeIsoFormat(endDate) };
}
